<template>
    <div class="text_vue">
        <!-- 标题 -->
        <div class="vue_title">文档列表</div>
        <!--  -->
        <div class="text_main">
            <div class="main_condition">
                <div class="cond_left">
                    <el-button type="primary" class="btnBgColor_blue_empty" @click="addEdit('add')" size="small" icon="el-icon-plus">添加文档</el-button>
                    <el-input v-model="title" size="small" @keyup.enter.native="searchBtn" @clear='searchBtn' :clearable="true" placeholder="搜索文档名称"></el-input>
                    <el-select v-model="status" size="small" @change="searchBtn" :clearable="true" placeholder="请选择状态">
                        <el-option v-for="item in statusOptions" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                    <el-cascader v-model="classify" :options="classifyOptions" :props="prop" :show-all-levels="false" size='small' :clearable="true" @clear='searchBtn' @change="searchBtn" placeholder="请选择分类"></el-cascader>
                </div>
            </div>
            <!--  -->
            <div class="main_table">
                <el-table :data="list" v-loading="loading" style="width: 100%" header-cell-class-name="table-header" cell-class-name="cell-class">
                    <template slot="empty">
                        <empty-data img='no_img_b.png' text='暂无数据'></empty-data>
                    </template>
                    <el-table-column prop="id" label="ID" width="80" align="center"></el-table-column>
                    <el-table-column prop="title" label="标题"></el-table-column>
                    <el-table-column prop="category.name" label="所属分类"></el-table-column>
                    <el-table-column label="推荐">
                        <template slot-scope="scope">
                            <span v-if="scope.row.is_recommend==1">是</span>
                            <span v-if="scope.row.is_recommend==2">否</span>
                            <span></span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="hits" label="点击率"></el-table-column>
                    <el-table-column label="状态">
                        <template slot-scope="scope">
                            <el-switch v-model="scope.row.status" :active-value='1' :inactive-value='2' :key="scope.row.id" @change="changeSwitch($event,scope.row,scope.$index)"></el-switch>
                        </template>
                    </el-table-column>
                    <el-table-column prop="operator_name" label="操作人"></el-table-column>
                    <el-table-column prop="create_time" label="创建时间"></el-table-column>
                    <el-table-column prop="create_time" label="更新时间"></el-table-column>
                    <el-table-column class-name="last_class" label="操作" fixed="right" width="130">
                        <template slot-scope="scope">
                            <el-button type="text" size="small" @click="addEdit(scope.row.id)">编辑</el-button>
                            <el-button type="text" size="small" @click="delDocument(scope.row)">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <!-- 分页 -->
            <div class="page_box" v-if="total" style="padding-bottom: 20px;">
                <paging-page :total='total' align='right' @sizeChange='sizeChangeBtn' @currentChange='currentChangeBtn'></paging-page>
            </div>
        </div>
        <!-- 删除提示 -->
        <point-out ref='dialogTip' dialogTitle="提示" type="material" @close='closeDocument' dialogWidth='420px' :iconShow='true' iconColor='#FF7432' borderRadius='10px' :cancelShow='true' :affirmShow='true'>
            <div class="zc_dialog_tip_content">
                <div class="text">请确认是否删除文档，删除后将不可恢复</div>
            </div>
        </point-out>
    </div>
</template>

<script>
import PointOut from '@/components/point_out/point_out.vue'//提示
import EmptyData from '@/components/empty_data/empty_data.vue'//空数据
import PagingPage from '@/components/paging_page/paging_page.vue'//分页
export default {
    components: { PointOut, EmptyData, PagingPage },
    props: {},
    data() {
        return {
            // 名称
            title: '',
            // 分类
            classify: '',
            classifyOptions: [],
            // 状态
            status: '',
            statusOptions: [
                { value: 1, label: "开启" },
                { value: 2, label: "关闭" },
            ],
            // 
            list: [],
            loading: false,
            //
            page: 1,
            limit: 10,
            total: 0,
            // 
            delId: null,
            prop: {
                value: 'id',
                label: 'name',
                expandTrigger: 'hover',
                children: 'children',
                checkStrictly: true,
                emitPath: false
            },
        };
    },


    created() {
        this.getList()
        this.getCategory()
    },


    methods: {
        // 分类
        getCategory() {
            var params = {
                parent_id: 10
            }
            this.$helpApi.categoryList(params).then(res => {
                if (res.code == 1000) {
                    let arr = res.result || []
                    this.classifyOptions = arr
                } else {
                    this.$errMsg(res.message)
                }
            })
        },
        // 数据列表
        getList() {
            this.loading = true
            var params = {
                page: this.page,
                limit: this.limit,
                title: this.title,
                category_id: this.classify,
                status: this.status,
            }
            this.$helpApi.documentList(params).then(res => {
                this.loading = false
                if (res.code == 1000) {
                    this.list = res.result.data
                    this.total = res.result.total
                } else {
                    this.$errMsg(res.message)
                }
            })
        },
        // 筛选搜索
        searchBtn() {
            this.page = 1
            this.total = 0
            this.getList()
        },

        // 新增/编辑
        addEdit(id) {
            if (id == 'add') {
                this.$router.push('/help/document/add_edit')
            } else {
                this.$router.push({
                    name: 'help_document_add_edit',
                    query: {
                        id: id
                    }
                })
            }
        },
        // 删除
        delDocument(info) {
            this.delId = info.id
            this.$refs.dialogTip.openDialogBtn()
        },
        // 确定删除
        closeDocument() {
            if (this.delId != null) {
                this.$helpApi.documentListDel(this.delId).then(res => {
                    if (res.code == 1000) {
                        this.$succMsg(res.message)
                        if (this.list.length == 1) {
                            this.searchBtn()
                        } else {
                            this.getList()
                        }
                        this.delId = null
                    } else {
                        this.$errMsg(res.message)
                    }
                })
            }
        },
        // 修改状态
        changeSwitch(val, info, index) {
            var params = {
                id: info.id,
                status: val
            }
            this.$helpApi.documentListChange(params).then(res => {
                if (res.code == 1000) {
                    this.list[index].status = val
                    this.$succMsg(res.message)
                } else {
                    this.list[index].status = val == 1 ? 2 : 1
                    this.$errMsg(res.message)
                }
            })
        },
        // 改变每页条数
        sizeChangeBtn(val) {
            this.limit = val
            this.page = 1
            this.getList()
        },
        // 改变页数
        currentChangeBtn(val) {
            this.page = val
            this.getList()
        },
    }
}
</script>
<style lang='scss' scoped>
.text_vue {
    width: 100%;
    .text_main {
        width: 100%;
        padding: 0 20px;
        .main_condition {
            width: 100%;
            padding: 20px 0;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .cond_left {
                display: flex;
                align-items: center;
                .el-button {
                    padding: 8px 10px;
                    margin-right: 20px;
                }
                .el-select {
                    margin-right: 20px;
                    width: 160px;
                }
                .el-input {
                    margin-right: 20px;
                    width: 160px;
                }
            }
        }
    }
}
</style>